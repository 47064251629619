import Box from 'components/Box/Box';
import Flex from 'components/Flex/Flex';
import Image from 'components/Image/Image';
import Loader from 'components/Layout/Loader/Loader';
import AddToCartButton from 'components/Product/AddToCartButton/AddToCartButton';
import Typography from 'components/Typography/Typography';
import { Spacer } from 'constants/styling';
import { useProduct } from 'features/product/queries';
import useUpdateCart from 'hooks/useUpdateCart';
import { ReactNode } from 'react';
import { ITileProduct } from 'types/ContentfulTypes';
import ContentfulBannerContentBlock from '../ContentfulBannerContentBlock/ContentfulBannerContentBlock';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';
import { BackgroundImage, StyledPrice, StyledProductLink } from './ContentfulProductBannerCard.styled';

interface ContentfulProductBannerCardProps {
  border?: 'default' | 'none';
  borderRadius?: Spacer;
  className?: string;
  content: ITileProduct;
  imageMinHeight?: string;
  priority?: boolean;
  scaleContent?: boolean;
}

const ContentfulProductBannerCard = ({
  border = 'default',
  borderRadius = 25,
  className,
  content,
  imageMinHeight = '200px',
  priority,
  scaleContent,
}: ContentfulProductBannerCardProps) => {
  const { background: contentfulBackground, image: contentfulImage, product } = content?.fields ?? {};

  const { data: productFull, isLoading } = useProduct({ code: product?.activeItems?.[0]?.code });
  const { addToCart } = useUpdateCart();

  const productImage = productFull?.images?.[0];

  if (!productFull) {
    return null;
  }

  const ProductLinkMapper = ({ children }: { children: ReactNode }) => {
    if (productFull) {
      return (
        <StyledProductLink height={imageMinHeight} url={productFull?.url}>
          {children}
        </StyledProductLink>
      );
    }

    return <>{children}</>;
  };

  return (
    <Flex
      border={border}
      borderRadius={borderRadius}
      className={className}
      flexDirection="column"
      fullHeight
      justifyContent="space-between"
      position="relative"
    >
      <Loader position="absolute" show={isLoading} />
      {contentfulBackground && (
        <Box fullHeight fullWidth left={0} position="absolute" top={0}>
          <BackgroundImage mobileImage={contentfulBackground} priority={priority} />
        </Box>
      )}
      <ProductLinkMapper>
        {contentfulImage ? (
          <ContentfulImageBlock content={contentfulImage} hasRelativeParent objectFit="contain" />
        ) : (
          productImage?.url && (
            <Image alt={productFull?.brand?.name} layout="fill" objectFit="contain" src={productImage?.url} />
          )
        )}
      </ProductLinkMapper>
      <ContentfulBannerContentBlock fullHeight={scaleContent}>
        {productFull?.name && (
          <Typography fontWeight="bold" maxLines={2}>
            {productFull.name}
          </Typography>
        )}
        <Flex justifyContent="space-between">
          {productFull?.price?.value && (
            <StyledPrice
              color="secondary-300"
              currentPrice={productFull.price.value}
              fontSize={100}
              oldPrice={productFull.strikePrice?.value}
              oldPriceFontSize={88}
            />
          )}
          {productFull?.atp && (
            <AddToCartButton
              addToCartMessage={productFull.atp.addToCartMessage}
              onAddToCart={() => addToCart({ product: { ...product, code: product?.code, name: productFull.name } })}
              showIcon
              size={75}
              withLabel={false}
            />
          )}
        </Flex>
      </ContentfulBannerContentBlock>
    </Flex>
  );
};

export default ContentfulProductBannerCard;
